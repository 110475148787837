// import { useState } from "react";
// import "./App.css";

// export default function App() {
//   const [count, setCount] = useState(null);

//   function onClick() {
//     fetch(process.env.REACT_APP_API_URL, {
//       method: "POST",
//     })
//       .then((response) => response.text())
//       .then(setCount);
//   }

//   return (
//     <div className="App">
//       {count && <p>You clicked me {count} times.</p>}
//       <button onClick={onClick}>Click Me!</button>
//     </div>
//   );
// }

import React from 'react';
import "./App.css";

export default function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>KUBİLAY UYSAL</h1>
        <h2>HOŞGELDİNİZ</h2>
        <div className="construction-banner">
          <span className="construction-icon">🚧</span>
          <p>Site yapım aşamasında</p>
          <span className="construction-icon">🚧</span>
        </div>
        <nav className="App-nav">
          <a href="https://github.com/tgmkubi" className="App-link">
            GitHub
          </a>
          <a href="https://www.linkedin.com/in/kubilay-uysal-a1746bb7/" className="App-link">
            Linkedin
          </a>
          <a href="mailto:kubilayuysall@gmail.com" className="App-link">
            İletişim
          </a>
        </nav>
      </header>
    </div>
  );
}
